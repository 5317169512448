import slugify from 'slugify';

import { app } from './store';
import UAParser from 'ua-parser-js';
import isWebPSupported from 'is-webp-supported';
import { media } from './media';
class Detect {
    constructor() {
        this.parser = new UAParser();
        this.useragent = this.parser.getUA();
        this.result = this.parser.getResult(this.useragent);

        app.html = document.documentElement;

        app.device = {
            desktop: this.result.device.type === undefined,
            tablet: this.result.device.type === 'tablet',
            mobile: this.result.device.type === 'mobile',
        }

        app.ratio = {
            portrait: undefined,
            landscape: undefined,
            square: undefined,
        }

        app.browser = {
            name: slugify(this.result.browser.name, { lower: true }),
            version: slugify(this.result.browser.version, { lower: true }),
        };

        app.engine = {
            name: slugify(this.result.engine.name, { lower: true }),
            version: slugify(this.result.engine.version, { lower: true }),
        };

        app.os = {
            name: slugify(this.result.os.name, { lower: true }),
            version: slugify(this.result.os.version, { lower: true }),
        };

        app.cpu = this.result.cpu.architecture;

        app.webp = (app.browser.name == 'safari' && app.browser.version >= '15.6.1' && app.os.name == 'mac-os') ? true : isWebPSupported()

        this.classes()
        this.ratio()

        window.addEventListener('resize', this.onResize)
    }

    classes() {
        document.documentElement.classList.remove('no-js')
        // OS
        document.documentElement.classList.add(app.os.name)
        document.documentElement.setAttribute('os', app.os.name)
        // Engine
        document.documentElement.classList.add(app.engine.name)
        document.documentElement.setAttribute('engine', app.engine.name)
        // Browser
        document.documentElement.classList.add(app.browser.name)
        document.documentElement.classList.add(app.browser.version)
        document.documentElement.setAttribute('browser',app.browser.name)
        // Device
        // document.documentElement.classList.add(app.device.desktop ? 'desktop' : app.device.tablet ? 'tablet' : app.device.mobile ? 'phone' : '')
        document.documentElement.setAttribute('device', app.device.desktop ? 'desktop' : app.device.tablet ? 'tablet' : app.device.mobile ? 'phone' : null)
        // CPU
        document.documentElement.setAttribute('cpu', app.cpu)
        // WebP Images Support
        document.documentElement.setAttribute('webp', app.webp)
    }

    ratio = () => {
        let isPortrait = window.innerWidth < window.innerHeight,
            isLandscape = window.innerWidth > window.innerHeight,
            isSquare = window.innerWidth == window.innerHeight
        
        if(isPortrait){
            app.ratio.portrait = true
            app.ratio.landscape = false
            app.ratio.square = false
        } else if(isLandscape) {
            app.ratio.portrait = false
            app.ratio.landscape = true
            app.ratio.square = false
        } else  if(isSquare){
            app.ratio.portrait = false
            app.ratio.landscape = false
            app.ratio.square = true
        }
    }

    onResize = () => {
        if(media('<iPadPortrait')) {
            app.device = {
                desktop: false,
                tablet: false,
                mobile: true,
            }

            this.classes()
            this.ratio()
        } else if (media('>=iPadPortrait','<Laptop')){
            app.device = {
                desktop: false,
                tablet: true,
                mobile: false,
            }

            this.classes()
            this.ratio()
        } else if (media('>=Laptop')){
            app.device = {
                desktop: true,
                tablet: false,
                mobile: false,
            }

            this.classes()
            this.ratio()
        }
    }
}

export class Credits{
    constructor(){
        console.groupCollapsed((new Date().getFullYear()) + ' © Gioele Chiappani x NOOO');
        console.info('Made with love ♥ Gioele Chiappani – https://gioelechiappani.com/')
        console.info('Hosted by NOOO Agency – https://nooo.it/')
        console.groupEnd((new Date().getFullYear()) + ' © Gioele Chiappani x NOOO');
    }
}

const DetectionManager = new Detect()
export default DetectionManager