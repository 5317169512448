import Component from "../classes/Component"

import { app, menu } from '../utils/store'

import gsap from 'gsap'


export class Navigation extends Component {
    constructor(){
        super({
            element: '[data-navigation]',
            elements: {
                header: document.querySelector('header'),
                actions: document.getElementById('video-actions')
            }
        })

    }

    create(){
        super.create()
        
        app.nav = this.element
        app.header = this.elements.header
        app.actions = this.elements.actions
        app.root.style.setProperty('--navigation-width', `${app.nav.clientWidth}px`)
        app.root.style.setProperty('--header-height', `${app.header.clientHeight}px`)
        app.root.style.setProperty('--actions-height', `${app.actions.clientHeight}px`)

        if(this.elements.toggler){ this.elements.toggler.onclick = (event) => this.onClick() }
    }
    
    addEventListeners(){
        window.addEventListener('resize', () => app.root.style.setProperty('--navigation-width', `${app.nav.clientWidth}px`))
        window.addEventListener('resize', () => app.root.style.setProperty('--header-width', `${app.header.clientHeight}px`))
    }

    onClick = (entry) => {
        app.root.classList.toggle('navigation--open')
    }

    onClickMenu = (entry) => {
        toggleActive(this.elements.menu)
        toggleActive(this.elements.toggler)
    }

    destroy = () => { /** Code your own */ }
}