import map from 'lodash/map'

// Data::App Store
import { app } from './utils/store'

// User Detect
import { DetectionManager, Credits } from './utils/detect'

// Navigation
import { Navigation } from './modules/Navigation'

// Classes::Managers

class App {
    constructor() {
        app.init = true
        app.root = document.body

        app.create = {
            content: this.createContent.bind(this),
            navigation: this.createNavigation.bind(this),
            sidebar: this.createSidebar.bind(this),
            stream: this.createStream.bind(this),
            chat: this.createChat.bind(this),
        }

        map(app.create, (callback) => { return callback() })

        this.onResize()

        this.addEventListeners()

        console.log('App', app)
    }

    // Create Content
    createContent = () => {
        this.content = document.querySelector('[data-app-content]')

        app.content = this.content

        this.wrapper = document.querySelector('[data-wrapper]')

        app.wrapper = this.wrapper

        this.dropdown = {
            togglers: document.querySelectorAll('[data-bs-toggle="dropdown"]'),
        }

        this.accordion = {
            togglers: document.querySelectorAll('.accordion-button'),
        }

        this.modal = {
            elements: document.querySelectorAll('[data-modal]'),
            togglers: document.querySelectorAll('[data-modal-target]'),
            closers: document.querySelectorAll('[data-modal-close]')
        }

        app.current = this.template
        app.wrapper = this.wrapper
    }

    // Create Navigation
    createNavigation = () => {
        app.navigation = new Navigation()
    }
    /*
    ** Events
    */

    // On Loaded
    onPreloaded() {
        app.loading = false
        app.html.classList.remove('loading')
    }

    // On Page Resize
    onResize() {}

    // On Pop State
    onPopState() {}

    /*
    ** Listeners
    */
    addEventListeners() {
        window.addEventListener('popstate', this.onPopState.bind(this))
        window.addEventListener('resize', this.onResize.bind(this))
    }
}

new App()
